import Vue from 'vue'
import VueRouter from 'vue-router'
//import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

//前端页面路由表
const routes = [
  {
    path: '/',
    redirect: '/home' , /* 默认一打开访问这个路径 */ 
    
  },

  {
    path: '/SubmitAITool',
    name: 'SubmitAITool',
    component: () => import('../views/element/SubmitAITool.vue'),  /* 提交AI界面 */ 
    
  },

  {
    path: '/AdminLogin',
    name: 'AdminLogin',
    component: () => import('../views/adminsystem/AdminLogin.vue')  /* 管理员登陆界面 */ 
  },

  {
    path: '/AdminHome',
    name: 'AdminHome',
    component: () => import('../views/adminsystem/AdminHome.vue')  /* 管理员主界面 */ 
  },

  {
    path: '/home',
    name: 'home',
    component: () => import('../views/element/HomePage.vue')  /* 网站首页 */ 
  },

  {
    path: '/ToolDetail/:id',
    name: 'ToolDetail',
    meta:{title : '动态路由'},
    component: () => import('../views/element/ToolDetail.vue')  /* 工具详情页 */ 
  },

  {
    path: '/ToolClass/:productTypeCode',
    name: 'ToolClass',
    meta:{title : '动态路由'},
    component: () => import('../views/element/ToolClass.vue')  /* 工具分类页 */ 
  },

  {
    path: '/SearchResults/:productName',
    name: 'SearchResults',
    meta:{title : '动态路由'},
    component: () => import('../views/element/SearchResults.vue')  /* 搜索结果页 */ 
  },

  {
    path: '/dept',
    name: 'dept',
    component: () => import('../views/adminsystem/DeptView.vue')  /* 部门管理页面 */ 
  },

  {
    path: '/emp',
    name: 'emp',
    component: () => import('../views/adminsystem/EmpView.vue')  /* 员工管理页面 */ 
  },

  
  {
    path: '/AINews',
    name: 'AINews',
    component: () => import('../views/element/AINews.vue')  /* AI新闻界面 */ 
  },

  {
    path: '/AINewsDetail/:id',
    name: 'AINewsDetail',
    meta:{title : '动态路由'},
    component: () => import('../views/element/AINewsDetail.vue')  /* 新闻详情页 */ 
  },

  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import('../views/element/AboutUs.vue')  /* 关于我们界面 */ 
  },

  {
    path: '/ResponseFree',
    name: 'ResponseFree',
    component: () => import('../views/element/ResponseFree.vue') /* 免责声明界面 */ 
  },

  {
    path: '/AdvertisePut',
    name: 'AdvertisePut',
    component: () => import('../views/element/AdvertisePut.vue')  /* 广告推广界面 */ 
  },

  {
    path: '/EmptyPage',
    name: 'EmptyPage',
    component: () => import('../views/element/EmptyPage.vue')  /* 空界面 */ 
  },
]

const router = new VueRouter({
  routes
})

export default router
